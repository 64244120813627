import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class HiddenDataOverlay extends Component {
  render() {
    return (
      <div className='absolute top-1/2 -translate-y-1/2 z-10 px-4'>
        {!this.props.kdmAccess &&
          <div className='bg-grey-50 space-y-3 p-5 rounded drop-shadow-lg'>
            <h5 className="text-lg font-medium">Want to find Key Decision-Makers across the public sector?</h5>
            <p>
              To unlock direct contact information for thousands of Key Decision-Makers working in the UK public sector,
              <a
                href={'https://www.tussell.com/tussell-demo?hsCtaTracking=abbf36cb-1789-4afd-a845-3188a2061db8%7Cbcd810a4-7354-4ad3-aa74-675bf22ffb16'}
                target='_blank'
                className="text-green-500 font-medium" rel="noreferrer"
              > book a personalised demo</a>
              , or email us at
              <a href='mailto:support@tussell.com' className="text-green-500 font-medium"> support@tussell.com</a>
            </p>
          </div>
        }
      </div>
    );
  }
}

HiddenDataOverlay.propTypes = {
  kdmAccess: PropTypes.bool.isRequired
};
