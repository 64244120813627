import React from 'react';
import PropTypes from 'prop-types';

import update from 'immutability-helper';
import { cloneDeep } from 'lodash';

import DropdownFilter from '../filters/dropdown_filter';
import ModuleData from './module_data';
import HiddenDataOverlay from './hidden_data_overlay';
import CommonFilterLogic from '../common_filter_logic';
import ActionButtons from './action_buttons';
import FilterCheckbox from './filter_checkbox';

import DownArrow from '../../assets/images/icons/chevron-down.svg';
import Tick from './check.svg';

import Rails from '@rails/ujs';

const DEFAULT_FILTERS = {
  buyerByType: {
    exact: [],
    substring: [],
    wordSubstring: [],
    type: [],
    list: []
  },
  topLevelFunction: '',
  emailPresent: { present: false },
  phonePresent: { present: false }
};

const DEFAULT_DEC_MAKERS = Array(25).fill({
  name: 'Example Name',
  jobTitle: 'Full Job Title'
});

export default class KeyDecisionMakerModuleFilters extends CommonFilterLogic {
  static defaultFilters = DEFAULT_FILTERS;

  constructor(props) {
    super(props);

    const updateObj = {
      decisionMakers: { $set: DEFAULT_DEC_MAKERS },
      downloadAccess: { $set: props.kdmDownload },
      submittedFilters: { $set: {} },
      loading: { $set: true },
      filters: {
        buyerByType: {
          exact: { $set: [props.buyerId] }
        }
      },
      showFilters: { $set: false }
    };
    this.state = update(this.state, updateObj);
  }

  componentDidMount() {
    const url = `/key_decision_maker_module?${new URLSearchParams({ buyer_id: this.props.buyerId })}`;

    const fetchKDMs = async() => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json'
          }
        });
        const keyDecisionMakers = await response.json();

        this.setState({
          decisionMakers: keyDecisionMakers,
          submittedFilters: this.toSubmittableFilters(),
          loading: false
        });
      } catch (e) {
        // Report this error to Rollbar
        throw new Error('Could not fetch Key Decision-Makers in the KDM module', { cause: e });
      }
    };

    if (this.props.kdmAccess) fetchKDMs();
  }

  toSubmittableFilters() {
    const filters = cloneDeep(this.state.filters);
    if (filters.emailPresent.present) { filters.emailPresent = 'Y'; }
    if (filters.phonePresent.present) { filters.phonePresent = 'Y'; }

    return filters;
  }

  updateResults(e) {
    e.preventDefault();
    const url = '/key_decision_maker_module';
    const payload = JSON.stringify({
      filters: this.submittableFilterTree(),
      origin_page: this.props.origin,
      [Rails.csrfParam()]: Rails.csrfToken()
    });

    this.setState({ submitting: true });

    (async() => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },
          body: payload
        });

        const keyDecisionMakers = await response.json();
        this.setState({
          decisionMakers: keyDecisionMakers,
          // On success, update the submittedFilters state which is used for
          // the traversals.
          submittedFilters: this.toSubmittableFilters(),
          submitting: false,
          // Hide the "Update results" button by setting changed: false.
          changed: false
        });
      } catch (e) {
        this.setState({ submitting: false });
        throw e;
      }
    })();
  }

  toggleFilters() {
    this.setState({ showFilters: !this.state.showFilters });
  }

  renderFilters() {
    const { functions } = this.props;
    const { filters, changed, submitting } = this.state;
    return (
      <div className="p-2 pt-0.5 bg-grey-300 text-grey-700 rounded-b">
        <form className='mt-1 space-y-2' onSubmit={this.updateResults.bind(this)}>

          <div className='space-y-1'>
            <FilterCheckbox
              label={'Has direct email address'}
              value={filters.emailPresent}
              onChange={this.handleFilterChange.bind(this, 'emailPresent')}
              options={[
                ['present', 'Has direct email address']
              ]}
            ></FilterCheckbox>

            <FilterCheckbox
              label={'Has direct phone number'}
              value={filters.phonePresent}
              onChange={this.handleFilterChange.bind(this, 'phonePresent')}
              options={[
                ['present', 'Has direct phone number']
              ]}
            ></FilterCheckbox>
          </div>
          <DropdownFilter
            options={functions}
            value={filters.topLevelFunction}
            onChange={this.handleFilterChange.bind(this, 'topLevelFunction')}
            name='functionSelect'
          />

          <button
            className='flex justify-between items-center gap-x-1.5 py-1.5 px-2 bg-green-500 hover:bg-green-700 text-grey-50 font-medium w-full rounded text-start'
            type="submit"
            id='test-submit-filters'
            disabled={submitting && changed}
          >
            Update results
            <Tick className='fill-grey-50'></Tick>
          </button>
        </form>
      </div>
    );
  }

  renderComponent() {
    const { buyerName, origin, kdmAccess } = this.props;
    const { loading, decisionMakers, submittedFilters, downloadAccess } = this.state;

    const decisionMakerCount = decisionMakers.length;
    return (
      <div className='relative'>
        {loading && <HiddenDataOverlay kdmAccess={kdmAccess} />}
        <div className={loading ? 'hidden-data blur-sm pointer-events-none' : undefined}>

          <div className='p-5'>
            <div className='space-y-2'>
              <div className="flex justify-between">
                <h3 className="text-lg font-medium text-grey-900">Key Decision-Makers</h3>
                <span className="rounded border border-grey-600 p-1 text-grey-600 text-center text-sm h-fit font-medium">{decisionMakerCount}</span>
              </div>
              <p className="text-grey-700 text-[16px]">{buyerName}</p>
            </div>

            {decisionMakerCount > 0 && <ModuleData decisionMakers={decisionMakers} />}
            <ActionButtons
              origin={origin}
              submittedFilters={submittedFilters}
              loading={loading}
              downloadAccess={downloadAccess}
              decisionMakerCount={decisionMakerCount}
            />

            <a
              className={`flex cursor-pointer justify-between items-center gap-x-1.5 py-1.5 px-2 bg-grey-300 font-medium text-grey-700 w-full text-start ${this.state.showFilters ? 'rounded-t' : 'rounded'}`}
              onClick={this.toggleFilters.bind(this)}
            >
              <span>
                Filter
              </span>
              <button
                className='flex'
                aria-label='Close modal'
                data={{ close: true }}
                type='button'
              >
                <span aria-hidden='true' className='flex'>
                  <DownArrow className={`fill-grey-700 ${this.state.showFilters ? 'rotate-180' : ''}`} />
                </span>
              </button>
            </a>

            {this.state.showFilters && this.renderFilters()}
          </div>
        </div>
      </div>
    );
  }
}

KeyDecisionMakerModuleFilters.propTypes = {
  filters: PropTypes.object,
  functions: PropTypes.array.isRequired,
  buyerId: PropTypes.string.isRequired,
  buyerName: PropTypes.string.isRequired,
  kdmAccess: PropTypes.bool.isRequired,
  origin: PropTypes.string.isRequired
};

KeyDecisionMakerModuleFilters.defaultProps = {
  filters: DEFAULT_FILTERS
};
