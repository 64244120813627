import React, { Component } from 'react';
import PropTypes from 'prop-types';

// This is a select dropdown component.
export default class DropdownFilter extends Component {
  handleFieldChange(e) {
    const selection = e.target.value;
    this.props.onChange({ $set: selection });
  }

  renderOption(value, label) {
    return <option key={value} value={value}>{label}</option>;
  }

  render() {
    const { options } = this.props;

    return (
      <div>
        <select className="bg-grey-100 rounded border-0 py-[3px] w-full" name={this.props.name} value={this.props.value} onChange={this.handleFieldChange.bind(this)}>
          <option value=''>Select function</option>
          {options.map(([value, label]) => this.renderOption(value, label))}
        </select>
      </div>
    );
  }
}

DropdownFilter.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string
};
