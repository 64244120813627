import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FindMoreButton from './find_more_button';
import KeyDecisionMakersDownloadButton from '../key_decision_maker_download_button';

export default class ActionButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      functionsTree: [],
      loading: true
    };
  }

  // On mount, we want go get the Tussell function tree, which we use to
  // translate the function filter.
  componentDidMount() {
    const url = '/functions.json';
    (async() => {
      try {
        const response = await fetch(url, { method: 'GET' });
        const functionsTree = await response.json();
        this.setState({
          functionsTree,
          loading: false
        });
      } catch (e) {
        // Report this error to Rollbar
        throw new Error('Could not fetch function filters for KDM module', { cause: e });
      }
    })();
  }

  // This is helper function to traverse the tree and collect all the child
  // ids of a top level function.
  collectChildAndParentIds(selectedId) {
    // Check the functions tree has been loaded.
    if (this.state.loading) return [];

    // We can traverse it and collect the child ids.
    const functionsTree = this.state.functionsTree;
    const functionBranch = functionsTree.find(branch => branch.id === Number(selectedId));
    const collectedIds = [functionBranch.id];

    const traverseTree = tree => {
      if (!tree.children) return;
      tree.children.forEach(branch => {
        collectedIds.push(branch.id);
        traverseTree(branch);
      });
    };

    traverseTree(functionBranch);
    return collectedIds;
  }

  // The KDM DataView and KDM Module don't use the same function filter.... 🙄
  // So for this traversal we need to translate one to the other.
  reformatFilters(filters) {
    // The default value for topLevelFunction is "".
    if (filters.topLevelFunction) {
      const topLevelFuncId = filters.topLevelFunction;
      const functionIds = this.collectChildAndParentIds(topLevelFuncId);
      filters.functions = functionIds;
    }

    // topLevelFunction only works on the KDM module so we can remove it
    // from filters for for the KDM data view.
    delete filters.topLevelFunction;
    return filters;
  }

  render() {
    const { submittedFilters, origin, downloadAccess, decisionMakerCount } = this.props;
    const traversalFilters = JSON.stringify(this.reformatFilters(submittedFilters));
    // Both buttons require data from the KDM module and the
    // functions tree. Both need to be present, before we enable the
    // the buttons.
    const loading = (this.props.loading || this.state.loading);

    return (
      <>
        <FindMoreButton
          origin={origin}
          filtersString={traversalFilters}
          loading={loading}
        />
        <KeyDecisionMakersDownloadButton
          filtersString={traversalFilters}
          downloadAccess={downloadAccess}
          isModule={true}
          decisionMakerCount={decisionMakerCount}
          loading={loading}
        />
      </>
    );
  }
}

ActionButtons.propTypes = {
  origin: PropTypes.string.isRequired,
  submittedFilters: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  downloadAccess: PropTypes.object.isRequired,
  decisionMakerCount: PropTypes.number.isRequired
};
