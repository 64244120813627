import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ModuleData extends Component {
  renderRows() {
    const decisionMakers = this.props.decisionMakers;

    return decisionMakers.map(({ name, jobTitle }, i) => {
      return (
        <tr className="text-left text-sm border-t border-grey-200 hover:bg-grey-200" key={`${name}-${jobTitle}-${i}`}>
          <td className="text-grey-900 font-medium py-2">{name}</td>
          <td className="py-2 text-grey-700 ">{jobTitle}</td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="max-h-80 overflow-y-scroll">
        <table className="border-spacing-2 w-full table-fixed mt-5 border-b border-grey-200">
          <thead>
            <tr className="text-grey-600 text-left text-sm border-t border-grey-200">
              <th className="py-2 font-medium">Name</th>
              <th className="py-2 font-medium">Title</th>
            </tr>
          </thead>
          <tbody>
            {this.renderRows()}
          </tbody>
        </table>
      </div>
    );
  }
}

ModuleData.propTypes = {
  decisionMakers: PropTypes.arrayOf(PropTypes.object).isRequired
};
