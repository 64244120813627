import React, { Component } from 'react';

import DownloadIcon from '../../assets/images/icons/download.svg';
import CrossIcon from '../../assets/images/icons/cross.svg';

import PropTypes from 'prop-types';

// A React version of our download button which allows you to also name the
// download before you submit it. This is used with our key contact changeset
// downloads so that we can tell the user what their download was for using
// their own name.
export default class NameableDownloadButton extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      name: '',
      open: false
    };
  }

  // When the user clicks the download button.
  handleClick = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  // If the user clicks the close button within the modal.
  handleClose = (e) => {
    e.preventDefault();
    this.closeModal();
  };

  // Changes to the download name
  handleNameChange = (e) => this.setState({ name: e.target.value });

  // When the modal itself is submitted, which means its time for the download
  handleModalSubmit = (e) => {
    e.preventDefault();
    this.closeModal();
    // NOTE: We aren't submitting the form here, we are just closing the modal.
    //       The form is submitted by the stimulus Downloads controller.
    //       See: `data-action='downloads#submitAndRender:prevent'` below.
  };

  closeModal() {
    this.setState({ open: false });
  }

  render() {
    return (
      <>
        <button
          className='flex justify-between items-baseline gap-x-1.5 py-1.5 px-2 mb-2 bg-green-500 hover:bg-green-700 font-medium text-grey-50 rounded w-full'
          type='submit'
          onClick={this.handleClick}
        >
          <span>
            Reveal contact information
          </span>
          <div className='h-4 w-4 grid place-content-center'>
            <DownloadIcon className='fill-grey-50' />
          </div>
        </button>

        <div className={`fixed top-0 left-0 right-0 bottom-0 bg-green-700 opacity-80 z-50 ${this.state.open ? '' : 'hidden'}`} onClick={this.handleClose} />
        <div ref={this.modalRef} id='test-download-name-modal' className={`fixed w-[600px] top-1/2 left-1/2 bg-grey-50 -translate-x-1/2 -translate-y-1/2 rounded p-5 space-y-3 z-50 ${this.state.open ? '' : 'hidden'}`} data-reveal='true'>
          <form
            onSubmit={this.handleModalSubmit}
            className='space-y-5'
            action={this.props.url}
            acceptCharset='UTF-8'
            method='post'
            data-controller='downloads'
            data-action='downloads#submitAndRender:prevent'
            data-downloads-notifications-outlet='.stimulus-notifications'
          >

            <input name='utf8' type='hidden' value='✓' />
            <input name='download[data_view_identifier]' type='hidden' value={this.props.dataViewIdentifier} />
            <input name='download[filters]' type='hidden' value={this.props.filters} />
            <input name='download[name]' type='hidden' required={true} value={this.state.name} />
            <input name='download[using_module]' type='hidden' required={true} value={this.props.isModule} />

            <span className='flex justify-between items-start space-x-5'>
              <div className='space-y-5'>
                <p className='font-medium'>
                  To reveal the direct contact information of the Key Decision-Makers in Tussell, you must download the contacts.
                </p>
                <p>
                  Please give your Key Decision-Maker downloads relevant names so if/when we send you updated records, you can easily
                  find the download we are referring to.
                </p>
              </div>
              <button className='close-button' aria-label='Close modal' data={{ close: true }} type='button' onClick={this.handleClose}>
                <span aria-hidden='true'>
                  <CrossIcon className='fill-grey-500' />
                </span>
              </button>
            </span>

            <div className='field flex flex-col space-y-2'>
              <label htmlFor='name'>Download name</label>
              <input type='text' name='name' className="rounded bg-grey-100 border-0 " value={this.state.name} required={true} onChange={this.handleNameChange} />
            </div>

            <button
              className='flex justify-between items-baseline gap-x-1.5 py-1.5 px-2 mb-2 bg-green-500 hover:bg-green-700 font-medium text-grey-50 rounded'
              type='submit'
              data-downloads-target='button'
            >
              <span>{`Download ${this.props.title}`}</span>
              <div className='h-4 w-4 grid place-content-center'>
                <DownloadIcon className='fill-grey-50' />
              </div>
            </button>
          </form>
        </div>

      </>
    );
  }
}

NameableDownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataViewIdentifier: PropTypes.string.isRequired,
  filters: PropTypes.string.isRequired,
  isModule: PropTypes.bool.isRequired
};
