import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Checkbox from './checkbox.svg';
import CheckboxChecked from './checkbox_checked.svg';

export default class FilterCheckbox extends Component {
  handleFieldChange(field, value) {
    const updateObj = { [field]: { $set: value } };
    this.props.onChange(updateObj);
  }

  renderOption(field, label) {
    return (
      <label className='space-x-2 select-none'>
        {
          this.props.value[field] ? <CheckboxChecked className="inline-block" /> : <Checkbox className="inline-block" />
        }
        <span>
          {label}
        </span>
        <input
          checked={this.props.value[field]}
          type='checkbox'
          className='hidden checkbox-filter__input'
          onChange={() => this.handleFieldChange(field, !this.props.value[field])}
          readOnly
        />
      </label>
    );
  }

  _renderOptions() {
    if (this.props.columns) {
      return this._renderOptionColumns();
    } else {
      return (
        <fieldset className='flex flex-wrap self-stretch gap-1'>
          {this.props.options.map(([field, label]) => this.renderOption(field, label))}
        </fieldset>
      );
    }
  }

  render() {
    return (
      <div className='space-y-2'>
        {this.props.children}
        {this._renderOptions()}
      </div>
    );
  }
}

FilterCheckbox.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  children: PropTypes.instanceOf(Element),
  columns: PropTypes.object.isRequired
};
