import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NameableDownloadButton from '../nameable_download_button';

import DownloadIcon from '../../assets/images/icons/download.svg';

export default class KeyDecisionMakersDownloadButton extends Component {
  renderDisabledButton() {
    const { downloadAccess } = this.props;
    let tooltipText = '';
    const quotaRemaining = downloadAccess.orgUsageRemaining;
    const kdmAllowance = downloadAccess.kdm_allowance;
    // If they do not have access and no credits.....
    if (!downloadAccess.userAccess && quotaRemaining > 0) {
      tooltipText = "To reveal contact-level email addresses and telephone numbers, you need to be granted permission to use your organisation's download credits. Please contact your Team Manager or email support@tussell.com for help.";
    // Else do they have any credits?
    } else if (kdmAllowance === 0 || kdmAllowance == null) {
      tooltipText = 'To download and see contact-level email addresses and telephone numbers, you must first purchase download credits. To unlock this premium module, contact your Tussell Account Manager or email us at support@tussell.com.';
    } else {
      tooltipText = 'Downloading these contacts would exceed your download limit - contact your Tussell Account Manager or email us at support@tussell.com.';
    }

    return (
      <div className='pb-2' data-controller='popup' data-action='mouseenter->popup#open mouseleave->popup#close' data-popup-position-value='bottom-start'>
        <button className='flex justify-between items-baseline gap-x-1.5 py-1.5 px-2 bg-grey-200 text-grey-500 font-medium rounded w-full text-start cursor-not-allowed' data-popup-target='button' disabled>
          <span>
            Reveal contact information
          </span>
          <DownloadIcon className='fill-grey-500 h-4 w-4' />
        </button>
        <span className='hidden w-56 absolute left-0 top-0 text-green-500 border-green-500' data-popup-target='popup'>
          <span className='flex flex-col gap-x-1.5 py-1.5 px-2 bg-grey-50 border border-green-500 rounded drop-shadow-sm'>
            {tooltipText}
          </span>
        </span>
      </div>
    );
  }

  render() {
    const {
      filtersString, downloadAccess, decisionMakerCount, loading, isModule
    } = this.props;

    // Can the user download this many KDM's?
    const enabledDownloads = downloadAccess.userAccess && downloadAccess.orgUsageRemaining >= decisionMakerCount;
    if (enabledDownloads && !loading) {
      return (
        <NameableDownloadButton
          url='/downloads'
          isModule={isModule}
          dataViewIdentifier='key_decision_makers'
          filters={filtersString}
          title="key decision-maker contacts"
        />
      );
    }

    return this.renderDisabledButton();
  }
}

KeyDecisionMakersDownloadButton.propTypes = {
  downloadAccess: PropTypes.object.isRequired,
  filtersString: PropTypes.string.isRequired,
  decisionMakerCount: PropTypes.number.isRequired,
  isModule: PropTypes.bool,
  loading: PropTypes.bool
};
